"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setClientInterfaceHook = void 0;
var react_1 = __importStar(require("react"));
var app_bridge_1 = __importStar(require("@shopify/app-bridge"));
var context_1 = require("../../context");
var ClientRouter_1 = require("../ClientRouter");
var RoutePropagator_1 = require("../RoutePropagator");
// eslint-disable-next-line @typescript-eslint/no-var-requires
var packageJson = require('../../package.json');
/**
 * Create an App Bridge client application from a config and pass it as the
 * value to the context provider.
 *
 * @public
 */
function Provider(_a) {
    var config = _a.config, router = _a.router, children = _a.children;
    /**
     * We are intentionally ignoring the `config` prop here as it yields a
     * bug on apps build with Next.js which loses the `host` query param
     * upon redirect.
     *
     * This was the behavior of the original `Provider` component prior to
     * the refactor in version 2.0.25.
     */
    var app = react_1.useMemo(function () { return app_bridge_1.default(config); }, []);
    react_1.useEffect(function () {
        if (app === null || app === void 0 ? void 0 : app.hooks) {
            app.hooks.set(app_bridge_1.LifecycleHook.DispatchAction, exports.setClientInterfaceHook);
        }
    }, [app]);
    var routerMarkup = (router === null || router === void 0 ? void 0 : router.history) && (router === null || router === void 0 ? void 0 : router.location) ? (react_1.default.createElement(Router, { history: router.history, location: router.location })) : null;
    return (react_1.default.createElement(context_1.AppBridgeContext.Provider, { value: app },
        routerMarkup,
        children));
}
/**
 * Augment actions with clientInterface metadata, identifying use of this library
 *
 * @internal
 */
var setClientInterfaceHook = function (next) {
    return function (action) {
        action.clientInterface = {
            name: '@shopify/app-bridge-react',
            version: packageJson.version,
        };
        return next(action);
    };
};
exports.setClientInterfaceHook = setClientInterfaceHook;
function Router(_a) {
    var history = _a.history, location = _a.location;
    ClientRouter_1.useClientRouting(history);
    RoutePropagator_1.useRoutePropagation(location);
    return null;
}
exports.default = Provider;
