"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureRequest = void 0;
var react_1 = require("react");
var Features_1 = require("@shopify/app-bridge/actions/Features");
var useAppBridge_1 = require("../../useAppBridge");
var useFeaturesAvailable_1 = require("../useFeaturesAvailable");
/**
 * useFeatureRequest hook
 *
 * @public
 * @remarks
 * React hook performs a feature request and then returns
 * the requested feature when permissions has been updated
 * @param {Group} group - The requested group enum
 * @param {string} action - The requested action. This param is optional
 */
function useFeatureRequest(group, action) {
    var app = useAppBridge_1.useAppBridge();
    var featuresAvailable = useFeaturesAvailable_1.useFeaturesAvailable();
    var _a = react_1.useState(), feature = _a[0], setFeature = _a[1];
    var handleFeatureUpdate = react_1.useCallback(function (featuresAvailable) {
        var updatedFeatures = featuresAvailable === null || featuresAvailable === void 0 ? void 0 : featuresAvailable[group];
        if (action && (updatedFeatures === null || updatedFeatures === void 0 ? void 0 : updatedFeatures[action])) {
            var actionPermission_1 = updatedFeatures === null || updatedFeatures === void 0 ? void 0 : updatedFeatures[action];
            setFeature(function (currentState) {
                if (JSON.stringify(actionPermission_1) !== JSON.stringify(currentState)) {
                    return actionPermission_1;
                }
                return currentState;
            });
            return;
        }
        setFeature(function (currentState) {
            if (JSON.stringify(updatedFeatures) !== JSON.stringify(currentState)) {
                return updatedFeatures;
            }
            return currentState;
        });
    }, [group, action]);
    react_1.useEffect(function () {
        Features_1.create(app).dispatch(Features_1.Action.REQUEST, { feature: group, action: action });
    }, [app, group, action]);
    react_1.useEffect(function () {
        handleFeatureUpdate(featuresAvailable);
    }, [featuresAvailable, handleFeatureUpdate]);
    return feature;
}
exports.useFeatureRequest = useFeatureRequest;
