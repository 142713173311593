"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContextualSaveBar = void 0;
var react_1 = require("react");
var ContextualSaveBar_1 = require("@shopify/app-bridge/actions/ContextualSaveBar");
var useAppBridge_1 = require("../../useAppBridge");
/**
 * useContextualSaveBar hook
 *
 * @remarks
 * React hook which wraps the Shopify App Bridge ContextualSaveBar action.
 *
 * @public
 */
function useContextualSaveBar() {
    var app = useAppBridge_1.useAppBridge();
    var _a = react_1.useState(), onSaveAction = _a[0], setOnSaveAction = _a[1];
    var _b = react_1.useState(), onDiscardAction = _b[0], setOnDiscardAction = _b[1];
    var contextualSaveBar = react_1.useMemo(function () {
        return ContextualSaveBar_1.create(app);
    }, [app]);
    var show = react_1.useCallback(function (options) {
        contextualSaveBar.dispatch(ContextualSaveBar_1.Action.SHOW);
        if (options) {
            contextualSaveBar.set(options);
        }
    }, [contextualSaveBar]);
    var hide = react_1.useCallback(function () {
        contextualSaveBar.dispatch(ContextualSaveBar_1.Action.HIDE);
    }, [contextualSaveBar]);
    var saveAction = react_1.useMemo(function () { return ({
        setOptions: function (_a) {
            var onAction = _a.onAction, saveAction = __rest(_a, ["onAction"]);
            setOnSaveAction(function () { return onAction; });
            if (Object.keys(saveAction).length) {
                contextualSaveBar.set({ saveAction: saveAction });
            }
        },
    }); }, [contextualSaveBar]);
    var discardAction = react_1.useMemo(function () { return ({
        setOptions: function (_a) {
            var onAction = _a.onAction, discardAction = __rest(_a, ["onAction"]);
            setOnDiscardAction(function () { return onAction; });
            if (Object.keys(discardAction).length) {
                contextualSaveBar.set({ discardAction: discardAction });
            }
        },
    }); }, [contextualSaveBar]);
    react_1.useEffect(function () {
        return contextualSaveBar.subscribe(ContextualSaveBar_1.Action.DISCARD, function () {
            contextualSaveBar.dispatch(ContextualSaveBar_1.Action.HIDE);
            onDiscardAction === null || onDiscardAction === void 0 ? void 0 : onDiscardAction();
        });
    }, [contextualSaveBar, onDiscardAction]);
    react_1.useEffect(function () {
        return contextualSaveBar.subscribe(ContextualSaveBar_1.Action.SAVE, function () {
            contextualSaveBar.dispatch(ContextualSaveBar_1.Action.HIDE);
            onSaveAction === null || onSaveAction === void 0 ? void 0 : onSaveAction();
        });
    }, [contextualSaveBar, onSaveAction]);
    return { show: show, hide: hide, saveAction: saveAction, discardAction: discardAction };
}
exports.useContextualSaveBar = useContextualSaveBar;
