var breakpoints = {
  'breakpoints-xs': {
    value: '0px'
  },
  'breakpoints-sm': {
    value: '490px'
  },
  'breakpoints-md': {
    value: '768px'
  },
  'breakpoints-lg': {
    value: '1040px'
  },
  'breakpoints-xl': {
    value: '1440px'
  }
};

export { breakpoints };
