import { breakpoints } from './token-groups/breakpoints.mjs';
import { depth } from './token-groups/depth.mjs';
import { legacy } from './token-groups/legacy.mjs';
import { colors as colors$1 } from './token-groups/color.dark.mjs';
import { colors } from './token-groups/color.light.mjs';
import { motion } from './token-groups/motion.mjs';
import { shape } from './token-groups/shape.mjs';
import { spacing } from './token-groups/spacing.mjs';
import { typography } from './token-groups/typography.mjs';
import { zIndex } from './token-groups/zIndex.mjs';
import { tokensToRems } from './utilities.mjs';

/**
 * Values to convert to CSS custom properties.
 * @example {background: '#000'} // --p-background: #000;
 */

var osColorSchemes = {
  light: 'light',
  dark: 'dark'
};
/**
 * Polaris color schemes and their associated color tokens.
 */

var colorSchemes = {
  light: colors,
  dark: colors$1
};
var tokens = createTokens({
  breakpoints: tokensToRems(breakpoints),
  colorSchemes: colorSchemes,
  depth: depth,
  legacyTokens: tokensToRems(legacy),
  motion: motion,
  shape: tokensToRems(shape),
  spacing: tokensToRems(spacing),
  typography: tokensToRems(typography),
  zIndex: zIndex
});
/**
 * Identity function that simply returns the provided tokens, but additionally
 * validates the input matches the `Tokens` type exactly and infers all members.
 */

function createTokens(tokens) {
  return tokens;
}

export { osColorSchemes, tokens };
