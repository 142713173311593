"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigationHistory = void 0;
var react_1 = require("react");
var actions_1 = require("@shopify/app-bridge/actions");
var useAppBridge_1 = require("../../useAppBridge");
/**
 * useNavigationHistory hook
 *
 * @remarks
 * React hook which wraps the Shopify App Bridge History action.
 *
 * @public
 */
function useNavigationHistory() {
    var app = useAppBridge_1.useAppBridge();
    return react_1.useMemo(function () {
        var history = actions_1.History.create(app);
        function push(location) {
            history.dispatch(actions_1.History.Action.PUSH, location.pathname);
        }
        function replace(location) {
            history.dispatch(actions_1.History.Action.REPLACE, location.pathname);
        }
        return { push: push, replace: replace };
    }, []);
}
exports.useNavigationHistory = useNavigationHistory;
