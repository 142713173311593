var typography = {
  'font-family-sans': {
    value: "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
  },
  'font-family-mono': {
    value: "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace"
  },
  'font-size-1': {
    value: '12px'
  },
  'font-size-2': {
    value: '13px'
  },
  'font-size-3': {
    value: '14px'
  },
  'font-size-4': {
    value: '15px'
  },
  'font-size-5': {
    value: '16px'
  },
  'font-size-6': {
    value: '17px'
  },
  'font-size-7': {
    value: '20px'
  },
  'font-size-8': {
    value: '21px'
  },
  'font-size-9': {
    value: '24px'
  },
  'font-size-10': {
    value: '26px'
  },
  'font-size-11': {
    value: '27px'
  },
  'font-size-12': {
    value: '28px'
  },
  'font-size-13': {
    value: '42px'
  },
  'font-weight-regular': {
    value: '400'
  },
  'font-weight-medium': {
    value: '500'
  },
  'font-weight-semibold': {
    value: '600'
  },
  'font-weight-bold': {
    value: '700'
  },
  'line-height-1': {
    value: '16px'
  },
  'line-height-2': {
    value: '20px'
  },
  'line-height-3': {
    value: '24px'
  },
  'line-height-4': {
    value: '28px'
  },
  'line-height-5': {
    value: '32px'
  },
  'line-height-6': {
    value: '36px'
  },
  'line-height-7': {
    value: '44px'
  }
};

export { typography };
