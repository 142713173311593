"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = exports.ActionVerb = void 0;
var react_1 = require("react");
var ResourcePicker_1 = require("@shopify/app-bridge/actions/ResourcePicker");
Object.defineProperty(exports, "ActionVerb", { enumerable: true, get: function () { return ResourcePicker_1.ActionVerb; } });
Object.defineProperty(exports, "ResourceType", { enumerable: true, get: function () { return ResourcePicker_1.ResourceType; } });
var useAppBridge_1 = require("../../useAppBridge");
/**
 * ResourcePicker component
 *
 * @remarks
 * React component which wraps the Shopify App Bridge ResourcePicker action.
 *
 * @public
 */
function ResourcePicker(props) {
    var app = useAppBridge_1.useAppBridge();
    var focusReturnPoint = react_1.useRef(null);
    var resourceType = props.resourceType, onSelection = props.onSelection, onCancel = props.onCancel, open = props.open;
    // Initialize with open: false so the open action will dispatch on initial load
    var prevProps = react_1.useRef({ open: false });
    var picker = react_1.useMemo(function () {
        return ResourcePicker_1.create(app, {
            resourceType: ResourcePicker_1.ResourceType[resourceType],
            options: getActionOptions(props),
        });
        // We purposely want to reuse the same picker instance
        // and use a useEffect to call `picker.set` when needed
    }, [app]);
    react_1.useEffect(function () {
        var _a;
        var wasOpen = (_a = prevProps.current) === null || _a === void 0 ? void 0 : _a.open;
        if (wasOpen !== open) {
            if (open) {
                picker.dispatch(ResourcePicker_1.Action.OPEN);
            }
            else {
                picker.dispatch(ResourcePicker_1.Action.CLOSE);
            }
        }
        if (!wasOpen && open) {
            focusReturnPoint.current = document.activeElement;
        }
        else if (wasOpen &&
            !open &&
            focusReturnPoint.current != null &&
            document.contains(focusReturnPoint.current)) {
            focusReturnPoint.current.focus();
            focusReturnPoint.current = null;
        }
    }, [open]);
    react_1.useEffect(function () {
        var unsubscribeList = [];
        if (onSelection != null) {
            unsubscribeList.push(picker.subscribe(ResourcePicker_1.Action.SELECT, onSelection));
        }
        if (onCancel != null) {
            unsubscribeList.push(picker.subscribe(ResourcePicker_1.Action.CANCEL, onCancel));
        }
        return function () {
            var unsubscribe = unsubscribeList.pop();
            while (unsubscribe) {
                unsubscribe();
                unsubscribe = unsubscribeList.pop();
            }
        };
    }, [onSelection, onCancel]);
    react_1.useEffect(function () {
        var shouldUpdate = JSON.stringify(__assign(__assign({}, prevProps.current), { open: undefined })) !==
            JSON.stringify(__assign(__assign({}, props), { open: undefined }));
        if (!shouldUpdate) {
            return;
        }
        picker.set(getActionOptions(props));
        prevProps.current = props;
    }, [props]);
    return null;
}
function getActionOptions(props) {
    var initialQuery = props.initialQuery, initialSelectionIds = props.initialSelectionIds, showHidden = props.showHidden, allowMultiple = props.allowMultiple, selectMultiple = props.selectMultiple, actionVerb = props.actionVerb;
    var sharedOptions = {
        initialQuery: initialQuery,
        initialSelectionIds: initialSelectionIds,
        showHidden: showHidden,
        selectMultiple: selectMultiple !== null && selectMultiple !== void 0 ? selectMultiple : allowMultiple,
        actionVerb: actionVerb,
    };
    var result = sharedOptions;
    if (props.resourceType === 'Product') {
        var showVariants = props.showVariants, showDraft = props.showDraft, showArchived = props.showArchived, showDraftBadge = props.showDraftBadge, showArchivedBadge = props.showArchivedBadge;
        var productSpecificOptions = {
            showVariants: showVariants,
            showDraft: showDraft,
            showArchived: showArchived,
            showDraftBadge: showDraftBadge,
            showArchivedBadge: showArchivedBadge,
        };
        result = __assign(__assign({}, sharedOptions), productSpecificOptions);
    }
    return result;
}
exports.default = ResourcePicker;
