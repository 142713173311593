"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = exports.generateRedirect = void 0;
var Button = __importStar(require("@shopify/app-bridge/actions/Button"));
var ButtonGroup = __importStar(require("@shopify/app-bridge/actions/ButtonGroup"));
var Redirect = __importStar(require("@shopify/app-bridge/actions/Navigation/Redirect"));
function generateRedirect(appBridge, url, target, external) {
    if (target === void 0) { target = 'APP'; }
    if (url == null) {
        return undefined;
    }
    var redirect = Redirect.create(appBridge);
    var payload = external === true
        ? {
            url: url,
            newContext: true,
        }
        : url;
    return function () {
        redirect.dispatch(redirectAction(target, external), payload);
    };
}
exports.generateRedirect = generateRedirect;
function redirectAction(target, external) {
    if (external === true) {
        return Redirect.Action.REMOTE;
    }
    return Redirect.Action[target];
}
function transformActions(appBridge, _a) {
    var primaryAction = _a.primaryAction, secondaryActions = _a.secondaryActions, actionGroups = _a.actionGroups;
    var primary = transformPrimaryAction(appBridge, primaryAction);
    var secondary = __spreadArray(__spreadArray([], transformSecondaryActions(appBridge, secondaryActions)), transformActionGroups(appBridge, actionGroups));
    return {
        primary: primary,
        secondary: secondary,
    };
}
exports.transformActions = transformActions;
function transformAction(appBridge, action) {
    var style = action.destructive === true ? Button.Style.Danger : undefined;
    var button = Button.create(appBridge, {
        label: action.content || '',
        disabled: action.disabled,
        loading: action.loading,
        plain: action.plain,
        style: style,
    });
    if (action.onAction) {
        button.subscribe(Button.Action.CLICK, action.onAction);
    }
    var redirect = generateRedirect(appBridge, action.url, action.target, action.external);
    if (redirect != null) {
        button.subscribe(Button.Action.CLICK, redirect);
    }
    return button;
}
function transformPrimaryAction(appBridge, primaryAction) {
    if (primaryAction == null) {
        return undefined;
    }
    var primary = transformAction(appBridge, primaryAction);
    return primary;
}
function transformSecondaryActions(appBridge, secondaryActions) {
    if (secondaryActions === void 0) { secondaryActions = []; }
    var secondary = __spreadArray([], secondaryActions.map(function (secondaryAction) {
        return transformAction(appBridge, secondaryAction);
    }));
    return secondary;
}
function transformActionGroups(appBridge, actionGroups) {
    if (actionGroups === void 0) { actionGroups = []; }
    var buttonGroups = __spreadArray([], actionGroups.map(function (group) {
        var buttons = group.actions.map(function (groupAction) {
            return transformAction(appBridge, groupAction);
        });
        return ButtonGroup.create(appBridge, { label: group.title, plain: group.plain, buttons: buttons });
    }));
    return buttonGroups;
}
