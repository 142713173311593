"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var actions_1 = require("@shopify/app-bridge/actions");
var useAppBridge_1 = require("../../useAppBridge");
var Action = actions_1.ContextualSaveBar.Action, create = actions_1.ContextualSaveBar.create;
function ContextualSaveBar(_a) {
    var _b = _a.discardAction, discardAction = _b === void 0 ? { onAction: function () { return null; } } : _b, _c = _a.saveAction, saveAction = _c === void 0 ? { onAction: function () { return null; } } : _c, fullWidth = _a.fullWidth, leaveConfirmationDisable = _a.leaveConfirmationDisable, visible = _a.visible;
    var app = useAppBridge_1.useAppBridge();
    var onSaveAction = saveAction.onAction, saveActionProps = __rest(saveAction, ["onAction"]);
    var onDiscardAction = discardAction.onAction, discardActionProps = __rest(discardAction, ["onAction"]);
    var contextualSaveBar = react_1.useMemo(function () {
        return create(app, {
            saveAction: saveActionProps,
            discardAction: discardActionProps,
            fullWidth: fullWidth,
            leaveConfirmationDisable: leaveConfirmationDisable,
        });
    }, [app]);
    react_1.useEffect(function () {
        contextualSaveBar.set({
            saveAction: saveActionProps,
            discardAction: discardActionProps,
            fullWidth: fullWidth,
            leaveConfirmationDisable: leaveConfirmationDisable,
        }, visible);
    }, [
        app,
        contextualSaveBar,
        saveActionProps,
        discardActionProps,
        fullWidth,
        leaveConfirmationDisable,
        visible,
    ]);
    react_1.useEffect(function () {
        contextualSaveBar.subscribe(Action.DISCARD, onDiscardAction);
        contextualSaveBar.subscribe(Action.SAVE, onSaveAction);
        return function () {
            contextualSaveBar.unsubscribe();
        };
    }, [contextualSaveBar, onDiscardAction, onSaveAction]);
    react_1.useEffect(function () {
        if (visible) {
            contextualSaveBar.dispatch(Action.SHOW);
        }
        else {
            contextualSaveBar.dispatch(Action.HIDE);
        }
    }, [contextualSaveBar, visible]);
    return null;
}
exports.default = ContextualSaveBar;
