"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRouteChange = void 0;
var actions_1 = require("@shopify/app-bridge/actions");
function handleRouteChange(app, history) {
    return app.subscribe(actions_1.Redirect.Action.APP, function (_a) {
        var path = _a.path;
        history.replace(path);
    });
}
exports.handleRouteChange = handleRouteChange;
