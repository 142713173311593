"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAdminSection = exports.useNavigate = void 0;
var react_1 = require("react");
var Redirect = __importStar(require("@shopify/app-bridge/actions/Navigation/Redirect"));
var useAppBridge_1 = require("../../useAppBridge");
var useNavigationHistory_1 = require("../useNavigationHistory");
function useNavigate() {
    var app = useAppBridge_1.useAppBridge();
    var history = useNavigationHistory_1.useNavigationHistory();
    var redirect = react_1.useMemo(function () {
        return Redirect.create(app);
    }, [app]);
    var handleRedirect = react_1.useCallback(function (to, options) {
        var url = normalizeUrl(to);
        var isAppUrl = url.startsWith(app.localOrigin);
        var isHostUrl = url.startsWith(app.hostOrigin);
        var isRelative = url.startsWith('/');
        if (isAppUrl || isHostUrl || isRelative) {
            var path = getRelativePath(url);
            if (isHostUrl || (options === null || options === void 0 ? void 0 : options.target) === 'new' || (options === null || options === void 0 ? void 0 : options.target) === 'host') {
                redirect.dispatch(Redirect.Action.ADMIN_PATH, {
                    path: path.replace(/^\/admin/, ''),
                    newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
                });
                return;
            }
            if (((options === null || options === void 0 ? void 0 : options.target) === 'self' || !(options === null || options === void 0 ? void 0 : options.target)) && (options === null || options === void 0 ? void 0 : options.replace)) {
                history.replace({ pathname: path });
                return;
            }
            redirect.dispatch(Redirect.Action.APP, path);
            return;
        }
        redirect.dispatch(Redirect.Action.REMOTE, {
            url: url,
            newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
        });
    }, [redirect, history]);
    return react_1.useCallback(function (to, options) {
        if (isAdminSection(to)) {
            var convertedSection = __assign(__assign({}, to), { name: Redirect.ResourceType[to.name] });
            redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
                section: convertedSection,
                newContext: (options === null || options === void 0 ? void 0 : options.target) === 'new',
            });
            return;
        }
        handleRedirect(to, options);
    }, [handleRedirect, redirect]);
}
exports.useNavigate = useNavigate;
function isAdminSection(to) {
    return typeof to === 'object' && typeof (to === null || to === void 0 ? void 0 : to.name) === 'string';
}
exports.isAdminSection = isAdminSection;
function getPathWithSearchAndHash(_a) {
    var pathname = _a.pathname, search = _a.search, hash = _a.hash;
    return "" + pathname + (search || '') + (hash || '');
}
function normalizeUrl(to) {
    if (to instanceof URL) {
        return to.toString();
    }
    if (typeof to === 'string') {
        return to;
    }
    return getRelativePath(to);
}
function getRelativePath(to) {
    if (typeof to === 'string') {
        if (to.startsWith('/')) {
            return to;
        }
        return getPathWithSearchAndHash(new URL(to));
    }
    var search = to.search instanceof URLSearchParams ? to.search.toString() : to.search;
    return getPathWithSearchAndHash(__assign(__assign({}, to), { search: search }));
}
