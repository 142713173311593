"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.LeaveConfirmation = exports.confirm = exports.disable = exports.enable = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
/**
 * Action for the Leave Confirmation group
 * @public
 */
var Action;
(function (Action) {
    Action["ENABLE"] = "APP::LEAVE_CONFIRMATION::ENABLE";
    Action["DISABLE"] = "APP::LEAVE_CONFIRMATION::DISABLE";
    Action["CONFIRM"] = "APP::LEAVE_CONFIRMATION::CONFIRM";
})(Action = exports.Action || (exports.Action = {}));
function enable(payload) {
    if (payload === void 0) { payload = {}; }
    return helper_1.actionWrapper({
        group: types_1.Group.LeaveConfirmation,
        payload: payload,
        type: Action.ENABLE,
    });
}
exports.enable = enable;
function disable(payload) {
    if (payload === void 0) { payload = {}; }
    return helper_1.actionWrapper({
        group: types_1.Group.LeaveConfirmation,
        payload: payload,
        type: Action.DISABLE,
    });
}
exports.disable = disable;
function confirm(payload) {
    if (payload === void 0) { payload = {}; }
    return helper_1.actionWrapper({
        group: types_1.Group.LeaveConfirmation,
        payload: payload,
        type: Action.CONFIRM,
    });
}
exports.confirm = confirm;
/**
 * Leave Confirmation action set
 */
var LeaveConfirmation = /** @class */ (function (_super) {
    __extends(LeaveConfirmation, _super);
    /**
     * Returns a new instance of a Leave Confirmation action set
     * @param app the client application
     */
    function LeaveConfirmation(app, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, app, types_1.Group.LeaveConfirmation, types_1.Group.LeaveConfirmation) || this;
        _this.options = options;
        _this.set(options);
        return _this;
    }
    Object.defineProperty(LeaveConfirmation.prototype, "payload", {
        /**
         * Returns the action set payload
         */
        get: function () {
            return __assign({ id: this.id }, this.options);
        },
        enumerable: false,
        configurable: true
    });
    LeaveConfirmation.prototype.set = function (options) {
        this.options = helper_1.getMergedProps(this.options, options);
        return this;
    };
    /**
     * Dispatches a given action with the action set payload
     * @param action the action enum
     * @returns the action set instance
     */
    LeaveConfirmation.prototype.dispatch = function (action) {
        switch (action) {
            case Action.ENABLE: {
                var enableAction = enable(this.payload);
                this.app.dispatch(enableAction);
                break;
            }
            case Action.DISABLE: {
                var disableAction = disable(this.payload);
                this.app.dispatch(disableAction);
                break;
            }
            case Action.CONFIRM: {
                var confirmAction = confirm(this.payload);
                this.app.dispatch(confirmAction);
                break;
            }
        }
        return this;
    };
    return LeaveConfirmation;
}(ActionSet_1.ActionSet));
exports.LeaveConfirmation = LeaveConfirmation;
/**
 * Returns a new instance of a Leave Confirmation action set
 * @param app the client application
 */
function create(app, options) {
    if (options === void 0) { options = {}; }
    return new LeaveConfirmation(app, options);
}
exports.create = create;
