var styles = {
  "Badge": "Polaris-Badge",
  "sizeSmall": "Polaris-Badge--sizeSmall",
  "statusSuccess": "Polaris-Badge--statusSuccess",
  "statusInfo": "Polaris-Badge--statusInfo",
  "statusAttention": "Polaris-Badge--statusAttention",
  "statusWarning": "Polaris-Badge--statusWarning",
  "statusCritical": "Polaris-Badge--statusCritical",
  "statusNew": "Polaris-Badge--statusNew",
  "withinFilter": "Polaris-Badge--withinFilter",
  "Icon": "Polaris-Badge__Icon",
  "PipContainer": "Polaris-Badge__PipContainer"
};

export { styles as default };
