import { taggedTemplateLiteral as _taggedTemplateLiteral, slicedToArray as _slicedToArray, objectSpread2 as _objectSpread2, objectWithoutProperties as _objectWithoutProperties, toConsumableArray as _toConsumableArray } from './_virtual/_rollupPluginBabelHelpers.mjs';

var _excluded = ["colorSchemes"];

var _templateObject;

var BASE_FONT_SIZE = 16;
var UNIT_PX = 'px';
var UNIT_EM = 'em';
var UNIT_REM = 'rem'; // https://regex101.com/r/zvY2bu/1

var DIGIT_REGEX = new RegExp(String.raw(_templateObject || (_templateObject = _taggedTemplateLiteral(["-?d+(?:.d+|d*)"], ["-?\\d+(?:\\.\\d+|\\d*)"]))));
var UNIT_REGEX = new RegExp("".concat(UNIT_PX, "|").concat(UNIT_EM, "|").concat(UNIT_REM));
function getUnit() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var unit = value.match(new RegExp("".concat(DIGIT_REGEX.source, "(").concat(UNIT_REGEX.source, ")")));
  return unit && unit[1];
}
function toPx() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var unit = getUnit(value);
  if (!unit) return value;

  if (unit === UNIT_PX) {
    return value;
  }

  if (unit === UNIT_EM || unit === UNIT_REM) {
    return "".concat(parseFloat(value) * BASE_FONT_SIZE).concat(UNIT_PX);
  }
}
function toEm() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var fontSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BASE_FONT_SIZE;
  var unit = getUnit(value);
  if (!unit) return value;

  if (unit === UNIT_EM) {
    return value;
  }

  if (unit === UNIT_PX) {
    return "".concat(parseFloat(value) / fontSize).concat(UNIT_EM);
  }

  if (unit === UNIT_REM) {
    return "".concat(parseFloat(value) * BASE_FONT_SIZE / fontSize).concat(UNIT_EM);
  }
}
function toRem() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var unit = getUnit(value);
  if (!unit) return value;

  if (unit === UNIT_REM) {
    return value;
  }

  if (unit === UNIT_EM) {
    return "".concat(parseFloat(value)).concat(UNIT_REM);
  }

  if (unit === UNIT_PX) {
    return "".concat(parseFloat(value) / BASE_FONT_SIZE).concat(UNIT_REM);
  }
}

function rem(value) {
  return value.replace(new RegExp("".concat(DIGIT_REGEX.source, "(").concat(UNIT_PX, ")"), 'g'), function (px) {
    var _toRem;

    return (_toRem = toRem(px)) !== null && _toRem !== void 0 ? _toRem : px;
  });
}

function tokensToRems(tokenGroup) {
  return Object.fromEntries(Object.entries(tokenGroup).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        token = _ref2[0],
        properties = _ref2[1];

    return [token, _objectSpread2(_objectSpread2({}, properties), {}, {
      value: rem(properties.value)
    })];
  }) // We loose the `tokenGroup` inference after transforming the object with
  // `Object.fromEntries()` and `Object.entries()`. Thus, we cast the result
  // back to `T` since we are simply converting the `value` from px to rem.
  );
}
function createVar(token) {
  return "--p-".concat(token);
}
/**
 * Allowed Polaris keyframes.
 *
 * Result: ['p-keyframes-fade-in', 'p-keyframes-spin', etc...]
 */

function getKeyframeNames(motionTokenGroup) {
  return Object.keys(motionTokenGroup).map(function (token) {
    return token.startsWith('keyframes') ? "p-".concat(token) : null;
  }).filter(Boolean);
}
/**
 * Allowed Polaris token custom properties.
 *
 * Result: ['--p-background', '--p-text', etc...]
 */

function getCustomPropertyNames(tokens) {
  var colorSchemes = tokens.colorSchemes,
      restTokenGroups = _objectWithoutProperties(tokens, _excluded);

  var customPropertyNames = [].concat(_toConsumableArray(Object.keys(colorSchemes.light).map(function (token) {
    return createVar(token);
  })), _toConsumableArray(Object.entries(restTokenGroups).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2);
        _ref4[0];
        var tokenGroup = _ref4[1];

    return Object.keys(tokenGroup).map(function (token) {
      return createVar(token);
    });
  }).flat()));
  return customPropertyNames;
}

export { createVar, getCustomPropertyNames, getKeyframeNames, getUnit, toEm, toPx, toRem, tokensToRems };
