"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Cart = exports.setLineItemProperties = exports.removeLineItemDiscount = exports.setLineItemDiscount = exports.removeLineItem = exports.updateLineItem = exports.addLineItem = exports.removeProperties = exports.setProperties = exports.setDiscount = exports.updateCustomerAddress = exports.addCustomerAddress = exports.setCustomer = exports.update = exports.fetch = exports.Action = void 0;
var helper_1 = require("../helper");
var ActionSet_1 = require("../ActionSet");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["FETCH"] = "APP::CART::FETCH";
    Action["UPDATE"] = "APP::CART::UPDATE";
    Action["SET_CUSTOMER"] = "APP::CART::SET_CUSTOMER";
    Action["REMOVE_CUSTOMER"] = "APP::CART::REMOVE_CUSTOMER";
    Action["ADD_CUSTOMER_ADDRESS"] = "APP::CART::ADD_CUSTOMER_ADDRESS";
    Action["UPDATE_CUSTOMER_ADDRESS"] = "APP::CART::UPDATE_CUSTOMER_ADDRESS";
    Action["SET_DISCOUNT"] = "APP::CART::SET_DISCOUNT";
    Action["REMOVE_DISCOUNT"] = "APP::CART::REMOVE_DISCOUNT";
    Action["SET_PROPERTIES"] = "APP::CART::SET_PROPERTIES";
    Action["REMOVE_PROPERTIES"] = "APP::CART::REMOVE_PROPERTIES";
    Action["CLEAR"] = "APP::CART::CLEAR";
    Action["ADD_LINE_ITEM"] = "APP::CART::ADD_LINE_ITEM";
    Action["UPDATE_LINE_ITEM"] = "APP::CART::UPDATE_LINE_ITEM";
    Action["REMOVE_LINE_ITEM"] = "APP::CART::REMOVE_LINE_ITEM";
    Action["SET_LINE_ITEM_DISCOUNT"] = "APP::CART::SET_LINE_ITEM_DISCOUNT";
    Action["REMOVE_LINE_ITEM_DISCOUNT"] = "APP::CART::REMOVE_LINE_ITEM_DISCOUNT";
    Action["SET_LINE_ITEM_PROPERTIES"] = "APP::CART::SET_LINE_ITEM_PROPERTIES";
    Action["REMOVE_LINE_ITEM_PROPERTIES"] = "APP::CART::REMOVE_LINE_ITEM_PROPERTIES";
})(Action = exports.Action || (exports.Action = {}));
/**
 * Cart action
 * @internal
 */
function createCartAction(type, payload) {
    if (payload === void 0) { payload = {}; }
    return helper_1.actionWrapper({
        group: types_1.Group.Cart,
        type: type,
        payload: payload,
    });
}
function fetch() {
    return createCartAction(Action.FETCH);
}
exports.fetch = fetch;
function update(payload) {
    return createCartAction(Action.UPDATE, payload);
}
exports.update = update;
function setCustomer(payload) {
    return createCartAction(Action.SET_CUSTOMER, payload);
}
exports.setCustomer = setCustomer;
function addCustomerAddress(payload) {
    return createCartAction(Action.ADD_CUSTOMER_ADDRESS, payload);
}
exports.addCustomerAddress = addCustomerAddress;
function updateCustomerAddress(payload) {
    return createCartAction(Action.UPDATE_CUSTOMER_ADDRESS, payload);
}
exports.updateCustomerAddress = updateCustomerAddress;
function setDiscount(payload) {
    return createCartAction(Action.SET_DISCOUNT, payload);
}
exports.setDiscount = setDiscount;
function setProperties(payload) {
    return createCartAction(Action.SET_PROPERTIES, payload);
}
exports.setProperties = setProperties;
function removeProperties(payload) {
    return createCartAction(Action.REMOVE_PROPERTIES, payload);
}
exports.removeProperties = removeProperties;
function addLineItem(payload) {
    return createCartAction(Action.ADD_LINE_ITEM, payload);
}
exports.addLineItem = addLineItem;
function updateLineItem(payload) {
    return createCartAction(Action.UPDATE_LINE_ITEM, payload);
}
exports.updateLineItem = updateLineItem;
function removeLineItem(payload) {
    return createCartAction(Action.REMOVE_LINE_ITEM, payload);
}
exports.removeLineItem = removeLineItem;
function setLineItemDiscount(payload) {
    return createCartAction(Action.SET_LINE_ITEM_DISCOUNT, payload);
}
exports.setLineItemDiscount = setLineItemDiscount;
function removeLineItemDiscount(payload) {
    return createCartAction(Action.REMOVE_LINE_ITEM_DISCOUNT, payload);
}
exports.removeLineItemDiscount = removeLineItemDiscount;
function setLineItemProperties(payload) {
    return createCartAction(Action.SET_LINE_ITEM_PROPERTIES, payload);
}
exports.setLineItemProperties = setLineItemProperties;
/**
 * Cart
 */
var Cart = /** @class */ (function (_super) {
    __extends(Cart, _super);
    function Cart(app, options) {
        return _super.call(this, app, types_1.Group.Cart, types_1.Group.Cart, options ? options.id : undefined) || this;
    }
    Cart.prototype.dispatch = function (action, payload) {
        switch (action) {
            case Action.FETCH:
                this.dispatchCartAction(Action.FETCH);
                break;
            case Action.UPDATE:
                this.dispatchCartAction(Action.UPDATE, payload);
                break;
            case Action.SET_CUSTOMER:
                this.dispatchCartAction(Action.SET_CUSTOMER, payload);
                break;
            case Action.REMOVE_CUSTOMER:
                this.dispatchCartAction(Action.REMOVE_CUSTOMER, payload);
                break;
            case Action.ADD_CUSTOMER_ADDRESS:
                this.dispatchCartAction(Action.ADD_CUSTOMER_ADDRESS, payload);
                break;
            case Action.UPDATE_CUSTOMER_ADDRESS:
                this.dispatchCartAction(Action.UPDATE_CUSTOMER_ADDRESS, payload);
                break;
            case Action.SET_DISCOUNT:
                this.dispatchCartAction(Action.SET_DISCOUNT, payload);
                break;
            case Action.REMOVE_DISCOUNT:
                this.dispatchCartAction(Action.REMOVE_DISCOUNT, payload);
                break;
            case Action.SET_PROPERTIES:
                this.dispatchCartAction(Action.SET_PROPERTIES, payload);
                break;
            case Action.REMOVE_PROPERTIES:
                this.dispatchCartAction(Action.REMOVE_PROPERTIES, payload);
                break;
            case Action.CLEAR:
                this.dispatchCartAction(Action.CLEAR, payload);
                break;
            case Action.ADD_LINE_ITEM:
                this.dispatchCartAction(Action.ADD_LINE_ITEM, payload);
                break;
            case Action.UPDATE_LINE_ITEM:
                this.dispatchCartAction(Action.UPDATE_LINE_ITEM, payload);
                break;
            case Action.REMOVE_LINE_ITEM:
                this.dispatchCartAction(Action.REMOVE_LINE_ITEM, payload);
                break;
            case Action.SET_LINE_ITEM_DISCOUNT:
                this.dispatchCartAction(Action.SET_LINE_ITEM_DISCOUNT, payload);
                break;
            case Action.REMOVE_LINE_ITEM_DISCOUNT:
                this.dispatchCartAction(Action.REMOVE_LINE_ITEM_DISCOUNT, payload);
                break;
            case Action.SET_LINE_ITEM_PROPERTIES:
                this.dispatchCartAction(Action.SET_LINE_ITEM_PROPERTIES, payload);
                break;
            case Action.REMOVE_LINE_ITEM_PROPERTIES:
                this.dispatchCartAction(Action.REMOVE_LINE_ITEM_PROPERTIES, payload);
                break;
        }
        return this;
    };
    Cart.prototype.dispatchCartAction = function (type, payload) {
        this.app.dispatch(createCartAction(type, __assign(__assign({}, payload), { id: this.id })));
    };
    return Cart;
}(ActionSet_1.ActionSet));
exports.Cart = Cart;
function create(app, options) {
    return new Cart(app, options);
}
exports.create = create;
