"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialize = exports.Action = void 0;
var types_1 = require("../types");
var helper_1 = require("../helper");
var Action;
(function (Action) {
    Action["INITIALIZE"] = "APP::CLIENT::INITIALIZE";
})(Action = exports.Action || (exports.Action = {}));
function initialize() {
    return helper_1.actionWrapper({
        group: types_1.Group.Client,
        type: Action.INITIALIZE,
    });
}
exports.initialize = initialize;
